import {DataLayer} from '@nimbus-target/ui';
import {OverlayScrollbars} from 'overlayscrollbars';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);

OverlayScrollbars(document.body, {
	scrollbars: {
		autoHide: 'scroll',
	},
});

// TODO - adequar o serviceWorker para uso
serviceWorker.unregister();

reportWebVitals(function sendToAnalytics({id, name, value}) {
	DataLayer.push('gtag', 'send', 'event', {
		eventCategory: 'Web Vitals',
		eventAction: name,
		eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
		eventLabel: id,
		nonInteraction: true,
	});
});
