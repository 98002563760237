import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';
import {makeStyles} from '@material-ui/core/styles';

export const tourList = [
	{
		title: 'Cadastro de usuário',
		name: 'home',
		textFinish: 'pela página inicial',
		url: '/home',
	},
	{
		title: 'Tela de atendimento',
		name: 'atendimento',
		textFinish: 'pelo atendimento',
		url: '/chat',
	},
	{
		title: 'Cadastro de usuário',
		name: 'usuario',
		textFinish: 'pelo cadastro de usuários',
		url: '/user/list',
	},
	{
		title: 'Base de conhecimento',
		name: 'conhecimento',
		textFinish: 'pela base de conhecimento',
		url: '/article/list',
	},
	{
		title: 'Escala de atendimento',
		name: 'escala',
		textFinish: 'pela escala de atendimento',
		url: '/timetable/list',
	},
	{
		title: 'Gerenciamento de grupo',
		name: 'grupo',
		textFinish: 'pelo gerenciamento de grupos',
		url: '/group/list',
	},

	{
		title: 'Cadastro de Hub',
		name: 'hub',
		textFinish: 'pelo cadastro do hub',
		url: '/hub/list',
	},
	{
		title: 'Central de notificação',
		name: 'notificacao',
		textFinish: 'pela central de notificação',
		url: 'notification-center/list',
	},
	{
		title: 'Cadastro de PDV',
		name: 'pdv',
		textFinish: 'pelo cadastro de PDV',
		url: '/store/list',
	},
];

const TourProfile = () => {
	const useStyles = makeStyles(theme => ({
		root: {
			width: '100%',
		},
		buttonScreen: {
			'marginBottom': theme.spacing(1.5),
			'width': '100%',
			'& > button': {
				width: '100%',
			},
		},
	}));
	const classes = useStyles();

	const CustomLink = props => <Link {...props} />;

	return (
		<List component='nav' className={classes.root} aria-label='contacts' width='100%'>
			{tourList.map(tour => {
				return (
					<ListItem button={true} divider={true} href={'fff'} component={CustomLink}>
						<ListItemIcon>
							<StarIcon />
						</ListItemIcon>
						<ListItemText primary={tour.title} />
					</ListItem>
				);
			})}
		</List>
	);
};

TourProfile.propTypes = {
	stepName: PropTypes.string,
};

export default TourProfile;
