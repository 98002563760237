import {
	AccordionDetails,
	AccordionSummary,
	Badge,
	Accordion as MaterialAccordion,
	Typography,
	makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function Accordion(props) {
	const {children, expandIcon, title, bullet, defaultExpanded, disabled, titleColor, titleLeftIcon, titleRightIcon} =
		props;

	const useStyles = makeStyles(theme => ({
		'accordionBack': {
			backgroundColor: 'transparent',
			borderColor: 'transparent',
			boxShadow: 'none',
		},
		'&.MuiAccordion-root::before': {
			backgroundColor: 'transparent',
		},
		'title': {
			color: titleColor || '#A7ABBA',
		},
	}));

	const classes = useStyles();

	return (
		<MaterialAccordion defaultExpanded={defaultExpanded || false} className={classes.accordionBack} disabled={disabled}>
			<AccordionSummary expandIcon={expandIcon}>
				{bullet && (
					<Badge
						color='primary'
						variant='dot'
						overlap='circular'
						badgeContent=''
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'left',
						}}
						style={{left: -20, top: 7}}
					/>
				)}
				{titleLeftIcon && <React.Fragment>{titleLeftIcon}&nbsp;</React.Fragment>}
				<Typography className={classes.title}>{title}</Typography>
				{titleRightIcon && <React.Fragment>&nbsp;{titleRightIcon}</React.Fragment>}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</MaterialAccordion>
	);
}

Accordion.propTypes = {
	bullet: PropTypes.bool,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	expandIcon: PropTypes.node,
	title: PropTypes.string,
	titleLeftIcon: PropTypes.node,
	titleRightIcon: PropTypes.node,
};

export default Accordion;
