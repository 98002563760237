import {Divider, ListItemIcon, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import PropTypes from 'prop-types';
import {Fragment, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';

function Dropdown(props) {
	const {
		className,
		hasArrow,
		label,
		itensList,
		selected,
		labelEmpty,
		dataTour,
		openNewTab,
		excludeDivider,
		placement = 'bottom',
		hideScroll = false,
		internalPadding = 0,
		forceReload,
	} = props;

	const useStyles = makeStyles(theme => {
		return {
			popper: {
				'zIndex': theme.zIndexes.popper,
				'div&': {
					'&::-webkit-scrollbar': {
						display: hideScroll ? 'none' : '',
					},
				},
			},
		};
	});

	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const history = useHistory();

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleCloseLink = item => {
		if (item.link) {
			if (openNewTab) {
				window.open(item.link, '_blank');
			} else {
				history.push(item.link);
				if (forceReload) {
					window.location.reload();
				}
			}
		}
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// Return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	return (
		<Fragment>
			<Button
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup='true'
				onClick={handleToggle}
				className={className}
				disableRipple={true}
				endIcon={hasArrow ? <ExpandMoreTwoToneIcon /> : null}
				data-tour={dataTour}
			>
				{label}
			</Button>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				className={classes.popper}
				role={undefined}
				transition={true}
				placement={placement}
				style={{maxHeight: '30vh', overflow: 'auto'}}
			>
				{({TransitionProps, placement}) => (
					<Grow
						{...TransitionProps}
						style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', padding: internalPadding}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
									{itensList.map((item, index) => {
										if (item.disabled) return null;
										return (
											<div key={`MenuItemContainer-${index}`}>
												<MenuItem
													onClick={event => {
														handleClose(event);
														handleCloseLink(item);
													}}
													selected={selected}
												>
													{item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
													<Typography variant='inherit'>{item.label}</Typography>
												</MenuItem>
												{/* remove divider on last item */}
												<div className='dropdown-divider'>
													{!excludeDivider && index < itensList.length - 1 && <Divider variant='middle' />}
												</div>
											</div>
										);
									})}
									{itensList.length === 0 && labelEmpty && (
										<div>
											<MenuItem>
												<Typography variant='inherit'>{labelEmpty}</Typography>
											</MenuItem>
										</div>
									)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Fragment>
	);
}

Dropdown.propTypes = {
	className: PropTypes.string,
	dataTour: PropTypes.string,
	forceReload: PropTypes.bool,
	hasArrow: PropTypes.bool,
	itensList: PropTypes.instanceOf(Array).isRequired,
	label: PropTypes.node.isRequired,
	labelEmpty: PropTypes.string,
	selected: PropTypes.string,
};

export default Dropdown;
