import {Card, CardContent, Divider, IconButton, Tooltip, Typography, makeStyles} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import PersonIcon from '@material-ui/icons/Person';
import Button from 'components/buttons/Button';
import Input from 'components/inputs/Input';
import InputAutoComplete from 'components/inputs/InputAutoComplete';
import {useUserContext} from 'context/index';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {
	useAppValue,
	useDispatchSetAppError,
	useDispatchSetAppInfoMesssage,
	useDispatchSetAppSuccessMessage,
	useDispatchSetAppValue,
} from 'redux/hooks/app';
import {useDispatchPost} from 'redux/hooks/fetch';
import {timeSince} from 'utils/moment';

function PocketOfLeads() {
	// Styles
	const useStyles = makeStyles(theme => ({
		card: {
			marginBottom: theme.spacing(1),
			height: 68,
			width: '100%',
		},
		content: {
			padding: 0,
			width: '98%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		contentFirstRow: {
			padding: 0,
			width: '100%',
			height: '15%',
			textAlign: 'right',
		},
		timeSince: {
			fontSize: 12,
			fontStyle: 'italic',
			marginRight: '5px',
		},
		contentSecondRow: {
			padding: '0px 5px 0px 5px',
			width: '100%',
			height: '85%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
			alignItems: 'center',
		},
		icon: {
			width: '10%',
		},
		title: {
			width: '70%',
			marginLeft: '10px',
			maxWidth: '70%',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		actionButton: {
			width: '20%',
			textAlign: 'center',
		},
		noLeads: {
			textAlign: 'center',
			marginTop: '50%',
			color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.secondary.light,
			fontSize: 18,
		},
	}));

	const classes = useStyles();

	const post = useDispatchPost();
	const setAppError = useDispatchSetAppError();
	const setAppInfo = useDispatchSetAppInfoMesssage();
	const setAppSuccess = useDispatchSetAppSuccessMessage();

	const history = useHistory();

	const {groupType} = useUserContext();

	const {register, control} = useForm();

	const leadsWaitingOnPocket = useAppValue('leadsWaitingOnPocket');
	const setLeadsWaitingOnPocket = useDispatchSetAppValue('leadsWaitingOnPocket');

	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');

	const [defaultStoreSeller, setDefaultStoreSeller] = useState([]);
	const [tempGroup, setTempGroup] = useState('');
	const [tempSeller, setTempSeller] = useState('');

	useEffect(() => {
		// Refresh each 30 seconds, just to garantee if we miss some socket event
		let isMounted = true;
		const intervalId = setInterval(() => {
			if (!isMounted) return;
			post('/lead/list-from-pocket')
				.then(responseData => {
					setLeadsWaitingOnPocket(responseData.data);
				})
				.catch(err => {
					console.error(err);
				});
		}, 30000);
		// Clear interval
		return () => {
			clearInterval(intervalId);
			isMounted = false;
		};
	}, [post, setLeadsWaitingOnPocket, setAppError]);

	const requestResultParser = useCallback(data => {
		const leadsFromPocket = dynamicList();
		return data.map(item => {
			const howManyFromStore = leadsFromPocket.filter(data => {
				return data.formerGroupId === item._id;
			});

			return {id: item._id, label: item.name + ` (${howManyFromStore.length})`};
		});
	}, []);

	const requestResultParserSeller = useCallback(data => {
		const leadsFromPocket = dynamicList();
		return data.map(item => {
			const howManyFromStore = leadsFromPocket.filter(data => {
				return data.formerSallerId === item._id;
			});

			return {id: item._id, label: item.name + ` (${howManyFromStore.length})`};
		});
	}, []);

	const handleGetLead = leadId => {
		// Loading
		setLoading(true);
		// Request
		post('/lead/get-from-pocket', {leadId})
			.then(() => {
				const newList = leadsWaitingOnPocket.filter(data => {
					return data._id !== leadId;
				});
				setLeadsWaitingOnPocket([...newList]);
				setLoading(false);
				setAppSuccess('Lead adicionado à fila!');
			})
			.catch(err => {
				// Reset loading
				setLoading(false);
				// Check if lead was already gotten by another sales
				if (typeof err === 'object' && err.status === 429) {
					setAppInfo('Este lead acaba de ser pego por outro vendedor :(');
				} else {
					setAppError('Algo deu errado ao tentar pegar o lead, tente novamente');
				}
			});
	};

	const goToLead = leadId => {
		if (!leadId) return;
		history.push(`/chat/${leadId}`);
	};

	// This is a simple search method (I didn`t want to make something too complex using backend here)
	const dynamicList = () => {
		if (leadsWaitingOnPocket.length < 1) {
			return [];
		}

		const filteredLeads = leadsWaitingOnPocket.filter(item => {
			const isMatchingTitle = item.title.toLowerCase().includes(searchText.toLowerCase());
			const isMatchingGroup = !tempGroup || item.formerGroupId === tempGroup;
			const isTempSeller = !tempSeller || item.formerSallerId === tempSeller;

			return isMatchingTitle && isMatchingGroup && isTempSeller;
		});
		return filteredLeads;
	};

	const renderSearchField = () => {
		if (leadsWaitingOnPocket.length < 1) {
			return;
		}
		return (
			<div>
				<InputAutoComplete
					label={'PDV Antigo'}
					adornmentIcon={true}
					incomingValue={tempGroup || ''}
					control={control}
					hasEmptyOption={true}
					name='pdv'
					ref={register}
					onChange={value => setTempGroup(value.id)}
					requestParams={{type: 'store'}}
					requestMethod='post'
					requestPath='/group/list'
					requestResultParser={requestResultParser}
				/>
				{tempGroup && (
					<InputAutoComplete
						label={'Ultimo Vendedor'}
						adornmentIcon={true}
						control={control}
						incomingValue={tempSeller || ''}
						hasEmptyOption={true}
						name='Antigo vendedor'
						onChange={value => setTempSeller(value.id)}
						ref={register}
						defaultOptions={defaultStoreSeller}
						requestParams={{storeId: tempGroup}}
						requestMethod='post'
						requestPath='/group/sales-list'
						requestResultParser={requestResultParserSeller}
					/>
				)}
				<Input name='search' label='Digite um nome para filtrar...' onChange={e => setSearchText(e.target.value)} />
			</div>
		);
	};

	const renderLeadsWaiting = () => {
		if (leadsWaitingOnPocket.length < 1) {
			return (
				<div>
					<Typography className={classes.noLeads}>Não há leads no momento</Typography>
				</div>
			);
		}
		return dynamicList().map(data => {
			return (
				<Card key={data._id} className={classes.card}>
					<CardContent className={classes.content}>
						<div className={classes.contentFirstRow}>
							<Typography className={classes.timeSince}>
								{'Há ' + timeSince(new Date(data.distributionStatusDate))}
							</Typography>
						</div>
						<div className={classes.contentSecondRow}>
							<PersonIcon />
							<Typography className={classes.title}>{data.title}</Typography>
							{groupType === 'store' && (
								<Button color='primary' disabled={loading} handleClick={() => handleGetLead(data._id)}>
									Atender
								</Button>
							)}
							{groupType !== 'store' && (
								<Tooltip title={'Acessar lead'}>
									<IconButton edge='end' onClick={() => goToLead(data._id)}>
										<LinkIcon />
									</IconButton>
								</Tooltip>
							)}
						</div>
					</CardContent>
				</Card>
			);
		});
	};

	return (
		<div>
			{renderSearchField()}
			<Divider variant='middle' style={{marginTop: '15px', marginBottom: '15px'}} />
			{renderLeadsWaiting()}
		</div>
	);
}

export default PocketOfLeads;
