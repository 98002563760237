import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Dropdown from 'components/dropdown/Dropdown';
import {useUserContext} from 'context/index';
import {useDispatchPost} from 'redux/hooks/fetch';

function ChartsMenuDropdown() {
	//<<
	const useStyles = makeStyles(theme => ({
		textButton: {
			fontFamily: 'Montserrat',
			textTransform: 'capitalize',
			fontSize: 14,
			[theme.breakpoints.down('1300')]: {
				fontSize: 12,
			},
		},
	}));

	const classes = useStyles();
	const [charts, setCharts] = useState([]);

	const {clientId} = useUserContext();

	const post = useDispatchPost();

	useEffect(() => {
		if (!clientId) return;
		post('/report/list', {clientId})
			.then(responseData => {
				if (!responseData.data) {
					setCharts([]);
					return;
				}

				// Filter (TODO: This is too wrong bro, we need to improve and maybe take this rule to the backend)
				const chartRes = responseData.data.filter(e => {
					const chart = e;
					chart.link = `/chart/${chart._id}`;
					chart.label = chart.name;

					switch (chart.groupType) {
						case 'hub':
							chart.sort = 1;
							break;
						case 'group':
							chart.sort = 2;
							break;
						case 'pdv':
							chart.sort = 3;
							break;
						default:
							chart.sort = 4;
							break;
					}
					return e;
				});

				const sortedCharts = chartRes.sort((chart, nextChart) => chart.sort - nextChart.sort);

				setCharts(sortedCharts);
			})
			.catch(err => {
				console.error(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientId]);

	return (
		<Dropdown
			hasArrow={true}
			label='Dashboards'
			labelEmpty={'Nenhum relatório disponível'}
			itensList={charts}
			className={classes.textButton}
			dataTour='tour_home_menu_dashboard'
			openNewTab={true}
		/>
	);
}

export default ChartsMenuDropdown;
